import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {isPlatformBrowser} from '@angular/common'
import {SessionStorageService} from '../storage/session-storage.service'
import {NavigationEnd, Params, Router} from '@angular/router'
import {SESSION_STORAGE_QUERY_PARAMS_KEY, VOUCHER_QUERY_PARAM} from './query-params.types'
import {filter, take} from 'rxjs/operators'
import {Subscription} from 'rxjs'
import {ToastrService} from 'ngx-toastr'
import {TranslocoService} from '@ngneat/transloco'

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private routerSubscription?: Subscription
  private readonly queryParamOptions = {
    [VOUCHER_QUERY_PARAM]: {clearWhenStoring: true},
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly sessionStorage: SessionStorageService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly translationService: TranslocoService,
  ) {}

  applyQueryParams(currentUrl: URL, referrerUrl?: URL) {
    if (isPlatformBrowser(this.platformId)) {
      // Store query params in session storage if referrer host and current host are different
      if (referrerUrl?.host === currentUrl.host || currentUrl.search === '') {
        // If the current URL contains a voucher query param, then we want to store it in session storage
        if (currentUrl.searchParams.has(VOUCHER_QUERY_PARAM)) {
          return this.mergeAndStoreQueryParams(currentUrl)
        }
        return
      }
      this.mergeAndStoreQueryParams(currentUrl)
    }
  }

  /**
   * Overwrites the voucher query param in session storage.
   *
   * Please use {@link VoucherService} for a future proof interface.
   */
  storeVoucherOnParams(voucher: string) {
    const queryParams = this.readQueryParamsFromStorage() ?? {}
    queryParams[VOUCHER_QUERY_PARAM] = voucher
    this.storeQueryParams(queryParams)
  }

  private mergeAndStoreQueryParams(currentUrl: URL) {
    try {
      const savedQueryParams = this.readQueryParamsFromStorage()
      const params = Object.fromEntries(currentUrl.searchParams)
      this.storeQueryParams({...savedQueryParams, ...params})
      this.applyQueryParamOptions(params)
    } catch (e) {
      console.error('Error while merging query params', e)
    }
  }

  consumeQueryParams(): Record<string, string> | undefined {
    if (isPlatformBrowser(this.platformId)) {
      const queryParams = this.readQueryParamsFromStorage()
      this.sessionStorage.removeItem(SESSION_STORAGE_QUERY_PARAMS_KEY)
      return queryParams
    }
    return undefined
  }

  readQueryParamFromStorage(param: string): string | undefined {
    const qp = this.readQueryParamsFromStorage()
    if (qp) {
      return qp[param]
    }
  }

  private readQueryParamsFromStorage(): Record<string, string> | undefined {
    const queryParams = this.sessionStorage.getItem(SESSION_STORAGE_QUERY_PARAMS_KEY)
    if (queryParams) {
      return JSON.parse(queryParams)
    }
    return undefined
  }

  private storeQueryParams(queryParams: Record<string, string | undefined>) {
    this.sessionStorage.setItem(SESSION_STORAGE_QUERY_PARAMS_KEY, JSON.stringify(queryParams))
    if (Object.keys(queryParams).includes(VOUCHER_QUERY_PARAM)) {
      this.translationService
        .selectTranslate('shared.voucher.applied')
        .pipe(take(1))
        .subscribe(t => {
          this.toastr.success(t)
        })
    }
  }

  private applyQueryParamOptions(params: Record<string, string | undefined>) {
    const clearQps = Object.entries(this.queryParamOptions)
      .filter(([, options]) => options.clearWhenStoring)
      .map(([key]) => key)

    //If any queryParams matches qps that have been configured with a clearWhenStoring option, then clear
    // that parameter from the URL.
    if (clearQps.some(k => Object.keys(params).includes(k))) {
      this.clearQueryParams(clearQps, params)
    }
  }

  private clearQueryParams(clearQps: string[], params: Record<string, string | undefined>) {
    const queryParams: Params | null = {...params}
    clearQps.forEach(k => (queryParams[k] = null))

    if (this.routerSubscription) {
      //Make sure to unsubscribe here in case the route changes before the previous subscription completes as it
      //might have undesirable consequences.
      this.routerSubscription.unsubscribe()
    }

    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        return this.router.navigate([], {
          queryParams,
          queryParamsHandling: 'merge',
        })
      })
  }
}
